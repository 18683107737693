import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Service: React.FC = () => {
  const navigate = useNavigate();

  const serviceItems = [
    {
      id: 1,
      image: "/chatbot.png",
      alt: "AIチャットボット",
      title: "法人向けAIチャットボット",
      description: "カスタマイズ可能対話型AI導入支援",
      features: [
        "月額500円からChatGPT-4利用可能",
        "顧客窓口、社内ヘルプデスク対応",
        "セキュアな自社専用プラン",
      ],
    },
    {
      id: 2,
      image: "/training.png",
      alt: "AI教育プログラム",
      title: "AI人材育成研修",
      description: "AIを活用した新たな人材育成を支援",
      features: [
        "助成金活用で75％オフ",
        "最新AIの知見を獲得",
        "実務的なワークショップで業務改善",
      ],
    },
    {
      id: 3,
      image: "/development.png",
      alt: "システム開発・コンサルティング",
      title: "システム開発・コンサルティング", 
      description: "AIを活用した効率的な開発とDX推進支援",
      features: [
        "AIを活用した短期間での開発",
        "既存の業務分析と課題設計",
        "ROIを重視したツールの選定と導入支援",
      ],
    }
  ];

  const handleBackToTop = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="main-content">
      <div id="service-wrapper" className="section-wrapper">
        <section id="service" className="section">
          <div className="section-content">
            <h1 className="section-title">サービス一覧</h1>
            {serviceItems.map((item) => (
              <div key={item.id} className="service-item">
                <div className="service-image">
                  <img src={item.image} alt={item.alt} />
                </div>
                <div className="service-content">
                  <div className="service-content-inner">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <ul>
                      {item.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
            <button onClick={handleBackToTop} className="news-more-button">トップページへ戻る</button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Service;