import React from 'react';
import { Link } from 'react-router-dom';
import Contact from '../components/Contact';
import '../styles/Top.css';

function Top() {
  return (
    <main className="main-content">
      <section id="home" className="section">
        <div className="home-content">
          <div className="title-container">
            <h1 className="title-text">
              <span className="animated-text">Creating</span>
              <span className="animated-text">With</span>
              <span className="animated-text">AI</span>
            </h1>
            <p className="subtitle slide-up">
              AIソリューションをより身近に。<br />
              未来を創造する強力なパートナとしてビジネスを支援します。
            </p>
          </div>
          <div className="hero-image-container">
            <img src="/hero-image.png" alt="Hero" className="hero-image" />
          </div>
        </div>
      </section>

      <div id="about-wrapper" className="section-wrapper">
        <section id="about" className="section">
          <div className="section-content">
            <h2 className="section-title">ABOUT</h2>
            <div className="section-content">
              <p>Aplo Inc.は、AIを活用した革新的なソリューションを提供する企業です。</p>
              <p>私たちは、最先端の技術と創造的なアイデアを組み合わせ、ビジネスの課題解決と成長をサポートします。</p>

              <div className="company-info">
                <table className="company-table">
                  <tbody>
                    <tr>
                      <th>会社名</th>
                      <td>株式会社APLO（Aplo Inc.）</td>
                    </tr>
                    <tr>
                      <th>設立</th>
                      <td>2024年6月7日</td>
                    </tr>
                    <tr>
                      <th>資本金</th>
                      <td>400万円</td>
                    </tr>
                    <tr>
                      <th>代表取締役</th>
                      <td>中村 浩之</td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>〒153-0001 東京都渋谷区神宮前６丁目２３番４号桑野ビル２階</td>
                    </tr>
                    <tr>
                      <th>事業内容</th>
                      <td>
                        ・業務効率化支援<br />
                        ・AI人材育成プログラムの提供<br />
                        ・AIソリューション開発、導入コンサルティング<br />
                        ・システム開発
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <div id="news-wrapper" className="section-wrapper">
        <section id="news" className="section">
          <div className="section-content">
            <h2 className="section-title">NEWS</h2>
            <div className="news-list">
              <div className="news-item">
                <div className="news-image">
                  <img src="/training.png" alt="AI研修プログラム" />
                </div>
                <div className="news-content">
                  <div className="news-meta">
                    <p className="news-date">2024年8月15日</p>
                    <span className="news-category news">ニュース</span>
                  </div>
                  <h3 className="news-title">AI人材育成研修プログラムを発表</h3>
                  <p className="news-excerpt">最新のAI技術を学べる人材育成プログラムを開始しました。</p>
                </div>
              </div>
              <div className="news-item">
                <div className="news-image">
                  <img src="/chatbot.png" alt="AIチャットボット" />
                </div>
                <div className="news-content">
                  <div className="news-meta">
                    <p className="news-date">2024年7月1日</p>
                    <span className="news-category news">ニュース</span>
                  </div>
                  <h3 className="news-title">AIチャットボットサービスの提供を開始</h3>
                  <p className="news-excerpt">カスタマイズ可能な高性能AIチャットボットの提供を開始しました。</p>
                </div>
              </div>
              <div className="news-item">
                <div className="news-image">
                  <img src="/Aplo, Inc..png" alt="会社設立" />
                </div>
                <div className="news-content">
                  <div className="news-meta">
                    <p className="news-date">2024年6月19日</p>
                    <span className="news-category news">ニュース</span>
                  </div>
                  <h3 className="news-title">株式会社APLOを設立</h3>
                  <p className="news-excerpt">AI技術を活用したソリューションを提供する株式会社APLOを設立しました。</p>
                </div>
              </div>
            </div>
            <Link to="/news" className="news-more-button">ニュース一覧へ</Link>
          </div>
        </section>
      </div>

      <div id="service-wrapper" className="section-wrapper">
        <section id="service" className="section">
          <div className="section-content">
            <h2 className="section-title">SERVICE</h2>

            <div className="service-item">
              <div className="service-image">
                <img src="/chatbot.png" alt="AIチャットボット" />
              </div>
              <div className="service-content">
                <div className="service-content-inner">
                  <h3>法人向けAIチャットボット</h3>
                  <p>カスタマイズ可能対話型AI導入支援</p>
                  <ul>
                    <li>月額500円からChatGPT-4利用可能</li>
                    <li>顧客窓口、社内ヘルプデスク対応</li>
                    <li>セキュアな自社専用プラン</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="service-item">
              <div className="service-image">
                <img src="/training.png" alt="AI教育プログラム" />
              </div>
              <div className="service-content">
                <div className="service-content-inner">
                  <h3>AI人材育成研修</h3>
                  <p>AIを活用した新たな人材育成を支援</p>
                  <ul>
                    <li>助成金活用で75％オフ</li>
                    <li>最新AIの知見を獲得</li>
                    <li>実務的なワークショップで業務改善</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="service-item">
              <div className="service-image">
                <img src="/development.png" alt="システム開発・コンサルティング" />
              </div>
              <div className="service-content">
                <div className="service-content-inner">
                  <h3>システム開発・コンサルティング</h3>
                  <p>AIを活用した効率的な開発とDX推進支援</p>
                  <ul>
                    <li>AIを活用した短期間での開発</li>
                    <li>既存の業務分析と課題設計</li>
                    <li>ROIを重視したツールの選定と導入支援</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="contact-section" id="contact">
        <Contact />
      </div>
    </main>
  );
}

export default Top;